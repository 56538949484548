@import './base';

.inspection {
  background: url(./../images/inspection-bg.png);
  @include block_img;
  background-attachment: scroll;

  .main-button {
    @media (max-width: 568px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.inspection__buttons-wrapper {
  margin-bottom: 32px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 960px) {
    justify-content: center;
    gap: 12px;
  }
}

.inspection__items-wrapper {
  list-style-type: none;
  width: 76%;
  margin: 0 auto;
  min-height: 200px;
  margin-bottom: 40px;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 736px) {
    min-height: 260px;
  }
}

.inspection-items__item {
  margin-bottom: 20px;
  @extend .text;
  @extend .text_white;
  position: relative;

  &:before {
    font-family: 'icomoon' !important;
    @include pseudo;
    content: "\e90f";
    font-size: 16px;
    left: -28px;
    color: $red;
  }
}

.inspection-items__button {
  background-color: transparent;
  color: $gray;
  position: relative;
  display: inline-block;
  padding: 12px 12px 12px 52px;
  color: $gray;
  border: 2px solid $gray;
  border-radius: 6px;
  @extend .text;
  outline: none !important;
  @include animation;

  &.active {
    background-color: transparent !important;
    border-color: $yellow !important;
    color: $yellow !important;
  }

  &:hover {
    border-color: $yellow !important;
    color: $yellow !important;
  }

  &:before {
    font-family: 'icomoon' !important;
    @include pseudo;
    content: "\e905";
    font-size: 32px;
    left: 12px;
  }

  &_engine:before {
    content: "\e900";
  }

  &_computer:before {
    content: "\e906";
  }
  
  &_drive:before {
    content: "\e901";
  }
  
  &_salon:before {
    content: "\e902";
  }
  
  &_lawyer:before {
    content: "\e904";
  }

  @media (max-width: 1140px) {
    margin-bottom: 8px;
  }

  @media (max-width: 960px) {
    margin-bottom: 4px;
  }
}

.inspection-carousel__prev-icon {
  background-image: url(./../images/prev-gray.svg);
  width: 36px;
  height: 36px;
  border-radius: 50px;
  left: -56px;
  position: absolute;

  @media (max-width: 667px) {
    left: 12px;
  }
}

.inspection-carousel__next-icon {
  background-image: url(./../images/next-gray.svg);
  width: 36px;
  height: 36px;
  border-radius: 50px;
  right: -56px;
  position: absolute;

  @media (max-width: 667px) {
    right: 12px;
  }
}

.inspection-carousel {
  max-width: 448px;
  margin: 0 auto;
  max-height: 600px;
  margin-bottom: 42px;

  @media (max-width: 667px) {
    max-width: 100%;
    width: fit-content;
    margin: 0 auto;
  }
}

.inspection-carousel__wrapper {
  border-radius: 20px;
  border: 1px solid $black-light;
  overflow: hidden;

  @media (max-width: 667px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.inspection-carousel__item {
  img {
    max-height: 600px;
    width: auto;

    @media (max-width: 667px) {
      width: 100%;
      object-fit: cover;
    }
  }
}
