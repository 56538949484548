@import './base';

.testimonials {
  @include block;

  .main-button {
    @media (max-width: 568px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.testimonials-carousel {
  margin-bottom: 24px;
}

.slide-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 492px;

  @media (max-width: 1112px) {
    min-height: 600px;
  }

  @media (max-width: 960px) {
    min-height: 680px;
  }
}

.testimonial-item {
  width: 31%;
  background-color: $blue;
  border-radius: 12px;
  padding: 28px 32px;
  border: 1px solid $gray-light;
  position: relative;

  &:after {
    @include pseudo;
    background-image: url(./../images/quote_black.svg);
    right: 20px;
    top: 20px;
    width: 64px;
    height: 64px;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 12px 24px;
    margin-bottom: 4px;
  }

  @media (max-width: 414px) {
    padding: 12px 40px;
  }
}

.testimonial-item__title {
  margin-bottom: 12px;
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
  @include animation;
}

.testimonials-carousel__prev-icon {
  background-image: url(./../images/left-arrow.svg);
  width: 36px;
  height: 36px;
  border-radius: 50px;
  left: -12px;
  position: absolute;

  @media (max-width: 414px) {
    left: 4px;
  }
}

.testimonials-carousel__next-icon {
  background-image: url(./../images/right-arrow.svg);
  width: 36px;
  height: 36px;
  border-radius: 50px;
  right: -12px;
  position: absolute;

  @media (max-width: 414px) {
    right: 4px;
  }
}

.carousel-indicators li {
  background-color: $black-light;
}

.testimonial-item__text {
  @media (max-width: 1112px) {
    font-size: .6rem;
    line-height: .9rem;
  }
}