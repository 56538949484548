@import './base';

.price {
  @include block;
  background-color: #ECEFF3;
}

.price-wrapper {
  margin-top: 96px;
  align-items: flex-end;

  @media (max-width: 960px) {
    margin-top: 0;
  }
}

.price-card {
  background-color: $white;
  border-radius: 12px;
  border: 1px solid $gray-light;
  padding: 72px 28px 32px;
  position: relative;

  @media (max-width: 960px) {
    margin-top: 80px;
  }
}

.price-card__features {
  list-style-type: none;
  padding: 0;

  li {
    @extend .text_sm;
    padding-left: 20px;
    position: relative;
    margin-bottom: 16px;

    &:before {
      font-family: 'icomoon' !important;
      @include pseudo;
      content: "\e90f";
      font-size: 12px;
      left: 0;
      color: $red;
    }
  }
}

.price-card__img {
  background-color: $black;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  left: calc(50% - 40px);
  text-align: center;
  padding-top: 10px;
}

.price-card__title {
  line-height: 2.8rem;
}

.price-card__title_big {
  line-height: 1.4rem;
}

.price-card__divider {
  width: 100%;
  background-color: $gray-light;
  height: 2px;
  border-radius: 50px;
  margin-bottom: 28px;
  margin-top: 32px;
}

.price-number {
  font-size: 1.6rem;
}

.price-card_black {
  background-color: $black;
  color: $white;
  border-color: $black;
  box-shadow: 0px 4px 12px 2px rgba(23, 43, 77, 0.32);

  .price-card__img {
    background-color: $red;
  }

  .price-card_divider {
    background-color: $gray;
  }
}

.price__description {
  margin-top: 28px;
  color: $black-light;
}

.price-card__button {
  @media (max-width: 1140px) {
    font-size: .7rem !important;
    padding: 0 !important;
  }

  @media (max-width: 960px) {
    font-size: .8rem !important;
  }
}