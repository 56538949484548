@import './base';

.reason {
  background: url(./../images/reason-bg.png);
  @include block_img;

  .main-button {
    @media (max-width: 568px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.reason__text {
  margin-bottom: 28px;
  width: 96%;
}

.reason-arguments {
  margin: 8px 40px 52px auto;

  @media (max-width: 960px) {
    margin: 0;
    margin-top: 12px;
  }
}

.reason-arguments__item {
  margin-bottom: 48px;
  width: 88%;
  position: relative;

  &:before {
    @include pseudo;
    top: -8px;
    left: -60px;
    background-image: url(./../images/check-in-circle.svg);
    width: 40px;
    height: 40px;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding-left: 60px;

    &:before {
      top: -2px;
      left: 0;
      width: 32px;
      height: 32px;
      background-size: cover;
    }
  }
}