@import './base';

.welcome {
  padding: 64px 0;
}

.welcome-card {
  display: flex;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 52px;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

.welcome-card__avatar {
  width: 140px;
  height: 140px;
  position: relative;
  margin-right: 36px;

  &:after {
    @include pseudo;
    right: -2px;
    bottom: -8px;
    background-image: url(./../images/quote.svg);
    width: 44px;
    height: 44px;
  }

  @media (max-width: 375px) {
    margin-right: 0;
  }
}

.welcome-card__img {
  width: 140px;
  height: 140px;
}

.welcome-card__text {
  padding-top: 24px;
}

.feature-item__title {
  width: 76%;
  margin: 16px 0;
  line-height: 1rem;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.feature-item__text {
  width: 96%;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.feature-item {
  @media (max-width: 960px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }
}
