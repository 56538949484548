@import './base';

.header {
  background: url(./../images/header-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: $white;
  min-height: 96vh;
  padding-bottom: 124px;

  @media (max-width: 960px) {
    text-align: center;
  }
}

.header-logo {
  text-align: center;
  margin-right: 40px;
  padding-top: 16px;

  @media (max-width: 1140px) {
    margin-right: 32px;
  }
}

.header-logo__text {
  margin-top: -12px;
}

#header-nav > .header-nav-wrapper > li > a {
  color: $white;
  @include animation;

  &:hover {
    color: $yellow;
  }
}

.contacts {
  text-align: right;

  @media (max-width: 1140px) {
    min-width: 138px;
  }

  @media (max-width: 960px) {
    text-align: center;
    width: 154px;
    margin: 0 auto;
    margin-top: 16px;
  }
}

.social-links {
  display: flex;
  gap: 12px;
  margin-right: 36px;

  @media (max-width: 960px) {
    margin-right: 0;
    justify-content: center;
    margin-top: 12px;
  }
}

.social-links__item {
  display: inline-block;
  @include animation;

  &:hover {
    opacity: .7;
  }
}

.contacts__working-hours {
  display: block;
  font-size: .5rem;
  color: $gray;
  margin-bottom: 4px;
}

.header-content {
  padding-top: 60px;
}

.header-content .header-content__title {
  margin-bottom: 40px;
  text-align: left;

  &:after {
    display: none;
  }

  @media (max-width: 960px) {
    text-align: center;
  }
}

.header-content__img {
  margin-top: 68px;

  @media (max-width: 960px) {
    margin-top: 0;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 60px;
  }
}

.header-nav-wrapper {
  @media (max-width: 1140px) {
    flex-wrap: wrap;

    & > li > a {
      padding: 0;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 960px) {
    align-items: center;
  }
}

.header__menu-icon {
  background-image: url(./../images/menu.svg) !important;
}

.header-content-wrapper{
  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
}
