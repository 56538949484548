@import './base';

.modal-content {
  background-color: $white;
  padding: 24px;

  .close {
    @include animation;
    color: $red;
    outline: none;
  }

  .title-sm {
    min-height: 1.2rem;
    padding: 0;
  }

  @media (max-width: 414px) {
    padding: 12px;
  }
}

.modal-footer, .modal-header {
  border: none;
}

.modal-header {
  padding-bottom: 0;
}

.modal-dialog_policy {
  max-width: 80%;

  .modal-body {
    max-height: 72vh;
    overflow: scroll;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }

  .modal-title {
    @media (max-width: 436px) {
      font-size: .8rem;
    }
  }
}
