@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap');
@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon.eot?10bvdx');
  src:  url('./../fonts/icomoon.eot?10bvdx#iefix') format('embedded-opentype'),
        url('./../fonts/icomoon.ttf?10bvdx') format('truetype'),
        url('./../fonts/icomoon.woff?10bvdx') format('woff'),
        url('./../fonts/icomoon.svg?10bvdx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* || Colors */
$black: #172B4D;
$black-light: #42526E;

$white: #FAFBFC;

$gray: #97A0AF;
$gray-light: #DFE1E6;

$red: #FF5630;
$red-dark: #BE4A38;

$yellow: #FFC400;
$yellow-dark: #BE9916;

$blue: #DEEBFF;

$background-light: #F7F9FC;

/* || General styles */
html {
  font-size: 1rem;
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  color: $black;
  background-color: $background-light;
}

@mixin block {
  padding: 88px 0 100px;
}

@mixin block_img {
  padding: 88px 0 100px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
}

@mixin animation {
  transition: all .3s ease-in;
}

@mixin pseudo {
  position: absolute;
  content: '';
}

/* || Typography */
.title {
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-align: center;
  position: relative;
  margin-bottom: 72px;

  &:after {
    @include pseudo;
    bottom: -16px;
    width: 200px;
    height: 4px;
    background-color: $red;
    border-radius: 50px;
    left: calc(50% - 100px);
  }

  @media (max-width: 414px) {
    font-size: 1.8rem;

    &:after {
      @include pseudo;
      width: 80px;
      left: calc(50% - 40px);
    }
  }

  @media (max-width: 375px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.title-sm {
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  min-height: 2.8rem;
  margin-bottom: 20px;
}

.title_white {
  color: $white;
}

.text {
  font-size: .8rem;
  line-height: 1.25rem;
}

.text_sm {
  font-size: .7rem;
  line-height: 1.1rem;
}

.text_xsm {
  font-size: .6rem;
  line-height: .9rem;
}

.text_white, .title_white {
  color: $white;
}

.text_red {
  color: $red;
}

.text_medium {
  font-weight: 500;
}

.text_bold {
  font-weight: 700;
}

/* || Buttons */
.main-button {
  color: $black-light;
  background-color: $yellow;
  border: none;;
  height: 48px;
  padding: 0 32px;
  line-height: 48px;
  box-shadow: 0px 4px 0 $yellow-dark;
  @extend .text;
  @extend .text_medium;
  @include animation;
  margin-top: 24px;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 414px) {
    line-height: 1.2rem;
    padding: 4px 32px;
    height: auto;
    min-height: 48px;
  }
}

.main-button_red {
  color: $white;
  background-color: $red;
  box-shadow: 0px 4px 0 $red-dark;
  border: none;

  &:hover {
    background-color: $red-dark;
  }
}

.main-button_sm {
  height: 24px;
  padding: 0 12px;
  box-shadow: none;
  margin-top: 0;
  line-height: 24px;
  @extend .text_xsm;

  @media (max-width: 414px) {
    min-height: 24px;
  }
}

/* || Input */
.input {
  background-color: $white;
  height: 52px;
  border: 1px solid $gray-light;
  padding-left: 44px;
  outline: none !important;
  @extend .text;
  @extend .text_medium;

  &::placeholder {
    color: $gray;
  }
}

.input-wrapper {
  position: relative;

  &:after {
    font-family: 'icomoon' !important;
    @include pseudo;
    font-size: 20px;
    left: 14px;
    top: 10px;
    color: $gray;
  }

  &_avatar:after {
    content: "\e90c";
  }

  &_phone:after {
    content: "\e907";
  }

  &_brand:after {
    font-size: 18px;
    top: 12px;
    content: "\e908";
  }

  &_model:after {
    content: "\e90d";
  }

  &_year:after {
    font-size: 18px;
    top: 12px;
    content: "\e90e";
  }

  &_budget:after {
    font-size: 18px;
    top: 12px;
    content: "\e910";
  }
}

/* || Links */
.contacts__link {
  @extend .text_white;
  @extend .text_sm;
  @include animation;
  position: relative;
  display: inline-block;
  width: 100%;

  &:before {
    font-family: 'icomoon' !important;
    @include pseudo;
    left: -8px;
    font-size: 16px;
  }

  &:hover {
    color: $yellow;
    text-decoration: none;
  }

  &_phone:before {
    content: "\e907";
  }

  &_email:before {
    content: "\e90a";
  }

  &_address:before {
    content: "\e909";
  }
}
